import 'ol/ol.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {fromLonLat, toLonLat} from 'ol/proj.js';
import Overlay from 'ol/Overlay.js';

const h47Pos = fromLonLat([10.65367, 55.06510]);

const olMap = new Map({
  target: 'ol-map',
  layers: [
    new TileLayer({
      source: new OSM()
    })
  ],
  view: new View({
    center: h47Pos, //fromLonLat([10.65367, 55.06510]),
    // center: [0,0],
    zoom: 16
  })
});

const olMarker = new Overlay({
    position: h47Pos,
    positioning: 'center-center',
    element: document.getElementById('ol-marker'),
    stopEvent: false
});

olMap.addOverlay(olMarker);
